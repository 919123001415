import React, { useState, useEffect } from "react";
import axios from "axios";
import {toast} from "react-toastify"
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Alert,
  Dropdown,
  InputGroup,
  Form,
  Modal
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search, MoreHoriz, Delete } from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import ConfirmModal from "../../../components/ConfirmModal";
// import ProductTopupForm from "./ProductTopupForm";
// import EditTopupModal from "./EditTopupModal";
import { useSelector } from "react-redux";

import "../../style.css";

import Select, { components } from 'react-select';

const HistoryTransaksi = ({ refresh, handleRefresh }) => {
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [alertModal, setAlertModal] = React.useState("");
  const [modalAddToProduct, setModalAddToProduct] = React.useState(false);
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({
    id: "",
    category_name: ""
  });

  const [filter, setFilter] = React.useState({
    category: "",
    products: ""
  });

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const [allTopupMerchant, setAllTopupMerchant] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);

  const [search, setSearch] = React.useState("");

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showAddCategory, setShowAddCategory] = React.useState(false);
  const [hiddenCategory, setHiddenCategory] = React.useState("Inactive");

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const debouncedSearch = useDebounce(search, 1000);

  const closeEditTopupModal = () => setShowEditTopup(false);

  const inputRef = React.useRef();

  const [showEditTopup, setShowEditTopup] = useState(false);
  const [editTopupId, setEditTopupId] = useState(null);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [topupToDelete, setTopupToDelete] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [totalPages, setTotalPages] = useState(1);

  const [totalMerchantCost, setTotalMerchantCost] = useState(0);
  const [totalAdditionalCost, setTotalAdditionalCost] = useState(0);

  const [selectedBusinesses, setSelectedBusinesses] = useState([{ value: 'all', label: 'All Business' }]);
  const [businesses, setBusinesses] = useState([{ value: 'all', label: 'All Business' }]);

  const [saldo, setSaldo] = useState({});

  React.useEffect(() => {
    getProduct();
  }, [refresh]);

  React.useEffect(() => {
    // Set tanggal akhir ke hari ini
    const today = new Date();
    const endDateStr = today.toISOString().split('T')[0];
    setEndDate(endDateStr);

    // Set tanggal awal ke 20 hari sebelumnya
    const startDay = new Date();
    startDay.setDate(startDay.getDate() - 20);
    const startDateStr = startDay.toISOString().split('T')[0];
    setStartDate(startDateStr);
  }, []); // Empty dependency array means this runs once when component mounts

  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  console.log("userinfo", userInfo);


  const handleHiddenCategory = (status) => {
    setHiddenCategory(status);
  };

  const formikAddTopup = useFormik({
    initialValues: {
      image: null,
      business_id: userInfo.business_id,
      amount: "",
      top_up_description: ""
    },
    validationSchema: Yup.object({
      image: Yup.mixed().required("Please upload an image"),
      amount: Yup.number().required("Please enter an amount"),
      top_up_description: Yup.string().required("Please enter a description"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      console.log("values", values);
      const formData = new FormData();
      formData.append('image', values.image);
      formData.append('business_id', userInfo.business_id);
      formData.append('amount', values.amount);
      formData.append('top_up_description', values.top_up_description);

      try {
        
        const response = await axios.post("https://api.beetpos.com/api/v1/kios-topup/create-merchant", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("kkkkk", response);
        
        handleRefresh();
        closeAddCategoryModal();
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error(t("errorAddingTopUp"));
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const formikEditTopup = useFormik({
    initialValues: {
      id: "",
      code: "",
      amount: "",
      image: "",
      top_up_description: ""
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required('Amount is required')
        .positive('Amount must be positive'),
      top_up_description: Yup.string()
        .required('Description is required')
        .max(255, 'Description must be at most 255 characters')
    }),
    onSubmit: async (values) => {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const formData = new FormData();
        
        formData.append('business_id', userInfo.business_id);
        formData.append('amount', values.amount);
        formData.append('top_up_description', values.top_up_description);
        
        if (values.image && values.image instanceof File) {
          formData.append('image', values.image);
        }

        console.log('Submitting values:', values);
        console.log('FormData entries:', Array.from(formData.entries()));

        const response = await axios.put(
          `${API_URL}/api/v1/kios-topup/update-merchant/${values.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('API Response:', response.data);

        if (response.data) {
          toast.success(t('topupUpdatedSuccessfully'));
          handleRefresh();
          closeEditTopupModal();
        } else {
          throw new Error(response.data.message || 'Update failed');
        }
      } catch (error) {
        console.error('Error updating topup:', error);
        toast.error(error.response?.data?.message || t('errorUpdatingTopup'));
      }
    },
    enableReinitialize: true
  });

  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const showAddCategoryModal = () => {
    setShowAddCategory(true);
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 100);
  };
  const closeAddCategoryModal = () => {
    setAlertModal("");
    formikAddTopup.setFieldValue("image", "");
    formikAddTopup.setFieldValue("amount", "");
    formikAddTopup.setFieldValue("description", "");
    setShowAddCategory(false);
  };

  const showEditTopupModal = async (data) => {
    setEditTopupId(data.id);
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${API_URL}/api/v1/kios-topup/${data.id}`);
      const topupData = response.data.data;
      
      formikEditTopup.setValues({
        id: topupData.id,
        code: topupData.code,
        image: topupData.image ? `${API_URL}${topupData.image}` : null,
        amount: topupData.amount,
        top_up_description: topupData.top_up_description
      });
      
      setShowEditTopup(true);
    } catch (error) {
      console.error("Error fetching topup data:", error);
      toast.error(t("errorFetchingTopupData"));
    }
  };

  

  // const showConfirmModal = (data) => {
  //   formikEditCategory.setFieldValue("id", data.id);
  //   setShowConfirm(true);
  // };
  const closeConfirmModal = () => setShowConfirm(false);

  const closeAddToProduct = () => {
    setSelectedProducts([]);
    setModalAddToProduct(false);
  };

  const handleMode = () => {
    setSelectedData([]);
    setMultiSelect((state) => !state);
    setClearRows((state) => !state);
  };

  const handleSelected = (state) => setSelectedData(state.selectedRows);
  const handleSelectProducts = (e) => {
    const { value } = e.target;

    const copyProducts = [...selectedProducts];

    if (copyProducts.includes(value)) {
      copyProducts.splice(copyProducts.indexOf(value), 1);
    } else {
      copyProducts.push(value);
    }

    setSelectedProducts(copyProducts);
  };

  const handleAddToProduct = async (
    categories,
    products,
    image,
    amount,
    description,
    e
  ) => {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL;

    if (!products.length) {
      closeAddToProduct();
    }

    const sendData = {
      image,
      amount,
      description
    };

    try {
      enableLoading();
      await axios.post(
        `${API_URL}/api/v1/kios-topup/create-merchant`,
        sendData
      );
      disableLoading();
      handleRefresh();
      closeAddToProduct();
    } catch (err) {
      console.log(err);
    }
  };

  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const authToken = useSelector((state) => state.auth.authToken);
 
  const getAllTopup = async (search) => {
    try {
      const response = await axios.get(
        `https://api.beetpos.com/api/v1/kios-transaction`,
        {
          params: {
            page: 1,
            per_page: 10000,
            date_start: startDate,
            date_end: endDate,
            search: search
          }
        }
      );

      if (response.data.statusCode === 200) {
        let filteredTopups = response.data.data;
        
        if (selectedBusinesses.length && !selectedBusinesses.some(b => b.value === 'all')) {
          filteredTopups = filteredTopups.filter(topup => 
            selectedBusinesses.some(business => 
              business.value === topup.Business?.id
            )
          );
        }

        if (search) {
          const searchLower = search.toLowerCase();
          filteredTopups = filteredTopups.filter(topup => {
            return (
              topup.receipt_id?.toLowerCase().includes(searchLower) ||
              topup.description?.toLowerCase().includes(searchLower) ||
              topup.Business?.name?.toLowerCase().includes(searchLower) ||
              topup.Kios_Product?.name?.toLowerCase().includes(searchLower) ||
              topup.number?.toLowerCase().includes(searchLower)
            );
          });
        }

        const totalAdditional = filteredTopups.reduce((acc, item) => {
          // Hanya tambahkan additional_cost jika status bukan failed
          if (item.status?.toLowerCase() !== 'failed') {
            return acc + (item.Kios_Product?.additional_cost || 0);
          }
          return acc;
        }, 0);
        
        setTotalAdditionalCost(totalAdditional);
        setFilteredProducts(filteredTopups);
        setAllTopupMerchant(filteredTopups);
      }
    } catch (err) {
      console.error("Error fetching topup data:", err);
      setAllTopupMerchant([]);
      setFilteredProducts([]);
      setTotalAdditionalCost(0);
    }
  };

  React.useEffect(() => {
    getAllTopup(search);
  }, [selectedBusinesses, startDate, endDate, search]);

  // get all category
  const getProduct = async (search) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const filterProductCategory = search ? `?name=${search}` : "";
    try {
      const filterProduct = await axios.get(
        `${API_URL}/api/v1/kios-category${filterProductCategory}`
      );
      console.log("dwefef", filterProduct);
      setAllProducts(filterProduct.data.data);
    } catch (err) {
      setAllProducts([]);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const categoryData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item) => {
      return {
        id: item.id,
        business_name: item.Business?.name || '-',
        product_name: item.Kios_Product?.name || '-',
        number: item.number || '-',
        additional_cost: item.Kios_Product?.additional_cost || 0,
        merchant_cost: item.Kios_Product?.merchant_cost || 0,
        amount: item.Kios_Payment?.payment_total || 0,
        status: item.status?.toLowerCase(),
        date: formatDate(item.createdAt) + ' ' + formatTime(item.createdAt),
        description: item.description || '-',
        Business: item.Business // Tambahkan ini untuk akses langsung ke data Business
      };
    });
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Helper function to format time
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `${t("Business")}`,
      selector: "business_name",
      sortable: true,
      cell: (row) => row.Business?.name || '-'
    },
    {
      name: `${t("product")}`,
      selector: "product_name",
      sortable: true,
      width: "250px", // Menambahkan lebar kolom
      wrap: true
    },
    {
      name: `${t("phoneNumber")}`,
      selector: "number",
      sortable: true
    },
    {
      name: `${t("additionalCost")}`,
      selector: "additional_cost",
      sortable: true,
      cell: (row) => {
        const additionalCost = row.additional_cost || 0;
        return `Rp ${additionalCost.toLocaleString('id-ID')}`;
      }
    },
    {
      name: `${t("merchantCost")}`,
      selector: "merchant_cost",
      sortable: true,
      cell: (row) => {
        const merchantCost = row.merchant_cost || 0;
        return `Rp ${merchantCost.toLocaleString('id-ID')}`;
      }
    },
    {
      name: `${t("amount")}`,
      selector: "amount",
      sortable: true,
      cell: (row) => `Rp ${row.amount?.toLocaleString() || '0'}`
    },
    {
      name: `${t("status")}`,
      selector: "status",
      sortable: true,
      cell: (row) => {
        const statusMap = {
          "success": { text: "success", color: "#28a745", backgroundColor: "#28a745" },
          "failed": { text: "failed", color: "#dc3545", backgroundColor: "#dc3545" },
          "done": { text: "done", color: "#007bff", backgroundColor: "#007bff" }
        };
        
        // Convert status to lowercase for case-insensitive comparison
        const statusKey = row.status?.toLowerCase();
        const status = statusMap[statusKey] || { text: row.status, color: "#6c757d" };
        
        return (
          <div
            style={{
              backgroundColor: status.backgroundColor,
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
              fontWeight: "500",
              fontSize: "12px"
            }}
          >
            {status.text}
          </div>
        );
      }
    },
    {
      name: `${t("date")}`,
      selector: "date",
      sortable: true
    }
  ];

  const handleDelete = async () => {
    const categoryId = formikEditTopup.getFieldProps("id").value;
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      enableLoading();
      await axios.delete(`${API_URL}/api/v1/kios-topup/cancel/${categoryId}`);

      setAllTopupMerchant(allTopupMerchant.filter((item) => item.id !== categoryId));
      handleRefresh();

      disableLoading();
      closeConfirmModal();
    } catch (err) {
      console.log(err);
    }
  };

  const getSaldo = async () => {
    const BASE_URL = "https://api.beetpos.com";
    try {
      // Dapatkan saldo untuk setiap business yang dipilih
      if (selectedBusinesses.length && !selectedBusinesses.some(b => b.value === 'all')) {
        const saldoPromises = selectedBusinesses.map(async (business) => {
          const response = await axios.get(
            `${BASE_URL}/api/v1/kios-transaction/direct-get-saldo/${business.value}`,
            {
              headers: { Authorization: `Bearer ${authToken}` }
            }
          );
          if (response.data.statusCode === 201) {
            // Mengakses saldo dari response yang benar
            return { [business.value]: response.data.data.saldo };
          }
          return { [business.value]: 0 };
        });

        const saldoResults = await Promise.all(saldoPromises);
        const combinedSaldo = saldoResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setSaldo(combinedSaldo);
      } else {
        // Jika "All Business" dipilih
        const response = await axios.get(
          `${BASE_URL}/api/v1/kios-transaction/direct-get-saldo`,
          {
            headers: { Authorization: `Bearer ${authToken}` }
          }
        );
        if (response.data.statusCode === 201) {
          // Mengakses saldo dari response yang benar
          setSaldo({ all: response.data.data.saldo });
        }
      }
    } catch (error) {
      console.error("Error fetching saldo:", error);
      setSaldo({});
    }
  };

  useEffect(() => {
    getSaldo();
  }, [selectedBusinesses, refresh]); // Tambahkan selectedBusinesses sebagai dependency

  const renderSaldo = () => {
    if (Object.keys(saldo).length === 0) {
      return "Rp 0";
    }

    if (saldo.all) {
      // Pastikan nilai adalah number sebelum format
      const amount = Number(saldo.all) || 0;
      return `Rp ${new Intl.NumberFormat('id-ID').format(amount)}`;
    }

    // Pastikan semua nilai adalah number sebelum dijumlahkan
    const totalSaldo = Object.values(saldo).reduce((acc, curr) => acc + (Number(curr) || 0), 0);
    return `Rp ${new Intl.NumberFormat('id-ID').format(totalSaldo)}`;
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    console.log(`[handleSearch] Nilai pencarian diubah: "${searchValue}"`);
    setSearch(searchValue);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteTopup = (id, code) => {
    setTopupToDelete({ id, code });
    setShowDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    if (!topupToDelete) return;

    const API_URL = process.env.REACT_APP_API_URL;
    try {
      enableLoading();
      await axios.delete(`${API_URL}/api/v1/kios-topup/cancel/${topupToDelete.id}`);
      disableLoading();
      toast.success(t("topupDeletedSuccessfully"));
      handleRefresh();
    } catch (error) {
      console.error("Error deleting topup:", error);
      disableLoading();
      toast.error(t("errorDeletingTopup"));
    } finally {
      setShowDeleteConfirm(false);
      setTopupToDelete(null);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business`);
        if (response.data.data) {
          const businessOptions = response.data.data.map(business => ({
            value: business.id,
            label: business.name
          }));
          setBusinesses([{ value: 'all', label: 'All Business' }, ...businessOptions]);
        }
      } catch (error) {
        console.error("Error fetching businesses:", error);
      }
    };
    fetchBusinesses();
  }, []);

  const handleBusinessChange = (selectedOptions) => {
    let newSelection = selectedOptions;
    
    if (!selectedOptions) {
      newSelection = [{ value: 'all', label: 'All Business' }];
    } else if (selectedOptions.length > 1) {
      newSelection = selectedOptions.filter(option => option.value !== 'all');
    } else if (selectedOptions.length === 0) {
      newSelection = [{ value: 'all', label: 'All Business' }];
    }
    
    setSelectedBusinesses(newSelection);
    getAllTopup(search);
  };

  return (
    <Row>
      <ConfirmModal
        title={t("deleteProductCategory")}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        handleClick={handleDelete}
        state={showConfirm}
        closeModal={closeConfirmModal}
        loading={loading}
      />

      {/* <ModalAddToProduct
        t={t}
        state={modalAddToProduct}
        closeModal={closeAddToProduct}
        loading={loading}
        alert={alertModal}
        title={`${t("add")} "${selectedCategory.category_name}" ${t(
          "toProducts"
        )}`}
        selectedCategory={selectedCategory}
        selectedProducts={selectedProducts}
        allProducts={allProducts}
        handleSelectProducts={handleSelectProducts}
        handleAddToProduct={handleAddToProduct}
      /> */}

      {/* <ProductTopupForm
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showAddCategory}
        closeModal={closeAddCategoryModal}
        loading={loading}
        alert={alertModal}
        title={t("addProductCategory")}
        formikAddTopup={formikAddTopup}
        inputRef={inputRef}
        isSubmitting={formikAddTopup.isSubmitting}
      /> */}

      {/* <EditTopupModal
        show={showEditTopup}
        onHide={() => setShowEditTopup(false)}
        formik={formikEditTopup}
        loading={loading}
        alert={alertModal}
      /> */}

      <ConfirmModal
        title={t("deleteTopup")}
        body={t("deletingTopupConfirmation", { code: topupToDelete?.code })}
        buttonColor="danger"
        handleClick={confirmDelete}
        state={showDeleteConfirm}
        closeModal={() => setShowDeleteConfirm(false)}
        loading={loading}
      />

      <Modal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("imagePreview")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={selectedImage}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              objectFit: 'contain'
            }}
          />
        </Modal.Body>
      </Modal>

      <Col md={12} style={{ minHeight: "100%" }}>
        {alert ? <Alert variant="danger">{alert}</Alert> : ""}

        <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
          <div className="headerPage">
            <div className="headerStart">
              {!selectedData.length ? (
                <h3>{t("History Transaksi")}</h3>
              ) : (
                <h3>
                  {selectedData.length}
                  {t("itemSelected")}
                </h3>
              )}
            </div>
            <div className="headerEnd">
              {!multiSelect ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>

                <div className="bg-light rounded p-3 mr-3">
                <h3 style={{ color: "grey", fontSize: "8px", fontWeight: "bold" }}>
                  Saldo Saat Ini
                </h3>
                <h3 style={{ color: "green", fontSize: "11px" }}>
                  {renderSaldo()}
                </h3>
              </div>
                {/* <Button variant="primary" onClick={showAddCategoryModal}>
                  {t("topup")}
                </Button> */}
              </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="filterSection" style={{ maxWidth: "100%" }}>
            <Row className="align-items-center">
              <Col md={3}>
                <Select
                  isMulti
                  value={selectedBusinesses}
                  onChange={handleBusinessChange}
                  options={businesses}
                  placeholder="Pilih Bisnis"
                  closeMenuOnSelect={false}
                  components={{
                    Option: ({ children, ...props }) => {
                      return (
                        <components.Option {...props}>
                          <input
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={() => null}
                            style={{ marginRight: '8px' }}
                          />
                          {children}
                        </components.Option>
                      );
                    },
                    MultiValue: ({ children, ...props }) => (
                      <components.MultiValue {...props}>
                        {children}
                        <span
                          onClick={() => {
                            const newValue = selectedBusinesses.filter(
                              option => option.value !== props.data.value
                            );
                            handleBusinessChange(newValue.length ? newValue : [{ value: 'all', label: 'All Business' }]);
                          }}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '6px',
                            color: '#999'
                          }}
                        >
                          ×
                        </span>
                      </components.MultiValue>
                    )
                  }}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#e6f3ff' : 'white',
                      color: '#333',
                      '&:hover': {
                        backgroundColor: '#f5f5f5'
                      },
                      display: 'flex',
                      alignItems: 'center'
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#e6f3ff',
                      borderRadius: '4px',
                      padding: '2px 6px'
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: '#333'
                    }),
                    control: (provided) => ({
                      ...provided,
                      minHeight: '38px'
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: '#999'
                    })
                  }}
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("search")}
                    value={search}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("startDate")}
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    max={endDate}
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("endDate")}
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    min={startDate}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredProducts)}
            // style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
          
          <div style={{ 
            marginTop: '2px', 
            padding: '10px', 
            backgroundColor: '#f8f9fa',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
            <div style={{ 
              fontWeight: 'bold', 
              fontSize: '16px'
            }}>
              Total Additional Cost :  
              <span style={{ fontWeight: 'bold', fontSize: '19px', color: 'green', marginLeft: '10px' }}>
                Rp {totalAdditionalCost.toLocaleString('id-ID')}
              </span>
            </div>
          </div>
        </Paper>
      </Col>
    </Row>
  );
};

export default HistoryTransaksi;
