import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { DashboardPage } from "./pages/DashboardPage";
import { UserPage } from "./pages/User/UserPage";
import RevenueSharing from "./pages/RevenueSharing/RevenueSharingPage";
import { TransactionPage } from "./pages/Transaction/TransactionPage";
import { LimitSettingPage } from "./pages/LimitSetting/LimitSettingPage";
import Payment from "./pages/Payment/PaymentPage";
import PaymentGateway from "./pages/PaymentGateway/PaymentGatewayPage";
import { CurrencyPage } from "./pages/Currency/CurrencyPage";
import RevenueSharingDetail from './pages/RevenueSharing/Detail'
import CashlezTransactions from "./pages/CzTransactions/CashlezTransactions";
import CashlezTransactionReport from "./pages/CzTransactions/ReportCashlezTransaction"
import TopUpPPOB from "./pages/Product/TopUpPPOB";
import ProductPPOB from "./pages/Product/ProductPPOBs/ProductPPOB";
import HistoryTransaksi from "./pages/Product/HistoryTransaksi/HistoryTransaksi";
import PpobTransactionsReport from "./pages/Report/PPOBTransactionsReport";
// Sama persis seperti UserPage, cman di bagian dropdown outlet nya dikasih action untuk update integrate online shop
import { IntegrateOnlieShop } from './pages/IntegrateOnlineShop/UserPage'
import { RequestIntegration } from './pages/IntegrateOnlineShop/RequestIntegration'


export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/revenue-sharing" exact component={RevenueSharing} />
        <Route
          path="/revenue-sharing/detail/:id"
          component={RevenueSharingDetail}
        />
        <Route path="/integrate-online-shop" component={IntegrateOnlieShop} />
        <Route path="/request-integration" component={RequestIntegration} />
        <Route path="/cashlezTransaction" component={CashlezTransactions} />
        <Route
          path="/cashlezTransactionReport"
          component={CashlezTransactionReport}
        />
        <Route path="/PPOBTransactionsReport" component={PpobTransactionsReport} />
        <Route path="/TopUpPPOB" component={TopUpPPOB} />
        <Route path="/ProductPPOB" component={ProductPPOB} />
        <Route path="/HistoryTransaksi" component={HistoryTransaksi} />
        <Route path="/transaction" component={TransactionPage} />
        <Route path="/limit-setting" component={LimitSettingPage} />
        <Route path="/payment" component={Payment} />
        <Route path="/gateway" component={PaymentGateway} />
        <Route path="/currency" component={CurrencyPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
