import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  Alert,
  Dropdown,
  InputGroup,
  Form
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search, MoreHoriz, Delete } from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import ConfirmModal from "../../../components/ConfirmModal";
import EditPpobProductModal from "./EditPpobProductModal"

import "../../style.css";
import { toast } from "react-toastify";
import { utils as XLSXUtils, write as XLSXWrite } from 'xlsx';



const ProductPPOB = ({ refresh, handleRefresh,  userInfo }) => {
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const [alertModal, setAlertModal] = React.useState("");
  const [showConfirmBulk, setShowConfirmBulk] = React.useState(false);
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);

  const [filter, setFilter] = React.useState({
    category: "",
    products: ""
  });



  const [allCategories, setAllCategories] = React.useState([]);


  const [search, setSearch] = React.useState("");

  const [showEditPpobModal, setShowEditPpobModal] = React.useState(false);
  const [hiddenCategory, setHiddenCategory] = React.useState("Inactive");

  const debouncedSearch = useDebounce(search, 500);

  const closeEditPpobModal = () => setShowEditPpobModal(false);

  const inputRef = React.useRef();

  const initialCategory = {
    id: "",
    name: "",
    hidden: "Inactive"
  };

  const handleHiddenCategory = (status) => {
    setHiddenCategory(status);
  };

  // Define the validation schema
const PpobProductSchema = Yup.object().shape({
  category_id: Yup.mixed().nullable(),
  sub_category_id: Yup.mixed().nullable(),
  kode: Yup.string().nullable(),
  name: Yup.string().nullable(),
  merchant_cost: Yup.number().nullable(),
  base_price: Yup.number().nullable(),
  additional_cost: Yup.number().nullable(),
  admin_cost: Yup.number().nullable(),
  price: Yup.number().nullable(),
  active: Yup.boolean().nullable()
});

  // Definisikan fungsi refresh lokal
  const handleLocalRefresh = React.useCallback(() => {
    getKiosCategory(debouncedSearch, filter);
  }, [debouncedSearch, filter]);

  const formikEditPpobProduct = useFormik({
    initialValues: {
      id: "",
      category_id: "",
      sub_category_id: null,
      kode: "",
      name: "",
      merchant_cost: "",
      base_price: "",
      bassPrice: "",
      additional_cost: null,
      admin_cost: "",
      price: "",
      active: true
    },
    validationSchema: PpobProductSchema,
    onSubmit: async (values) => {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        
        const payload = {
          id: values.id,
          merchant_cost: Number(values.merchant_cost),
          additional_cost: values.additional_cost ? Number(values.additional_cost) : null,
          price: Number(values.price)
        };

        console.log('Update payload:', payload);

        const response = await axios.patch(
          `${API_URL}/api/v1/kios-product/${values.id}`, 
          payload
        );

        console.log('API Response update:', response.data);

        if (response.status === 200) {
          closeEditPpobModal();
          // Gunakan handleLocalRefresh sebagai fallback jika handleRefresh tidak ada
          if (typeof handleRefresh === 'function') {
            handleRefresh();
          } else {
            handleLocalRefresh();
          }
          toast.success(t('productUpdatedSuccessfully'));
        } else {
          throw new Error(response.data.message || 'Update failed');
        }
      } catch (error) {
        console.error('Error updating product:', error);
        const errorMessage = error.response?.data?.message || error.message || t('errorUpdatingProduct');
        toast.error(errorMessage);
      }
    }
  });


  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);


  const showEditPpobProduct = async (data) => {
    setAlertModal("");

    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${API_URL}/api/v1/kios-product/${data.id}`);
      const productData = response.data.data;

      // Pastikan semua nilai numerik dikonversi dengan benar
      formikEditPpobProduct.setValues({
        id: productData.id,
        category_id: productData.category_id,
        sub_category_id: productData.sub_category_id,
        kode: productData.kode,
        name: productData.name,
        merchant_cost: String(productData.merchant_cost || ''),
        base_price: String(productData.base_price || ''),
        bassPrice: String(productData.bassPrice || ''),
        additional_cost: productData.additional_cost ? String(productData.additional_cost) : '',
        admin_cost: String(productData.admin_cost || ''),
        price: String(productData.price || ''),
        active: productData.active
      });

      setShowEditPpobModal(true);

    } catch (error) {
      console.error('Error fetching product data:', error);
      toast.error(t('errorFetchingProductData'));
    }
  };



  const [filteredProducts, setFilteredProducts] = React.useState([]);

  const getKiosCategory = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterProductCategory = "";

    if (filter.category) {
      filterProductCategory += `?category_id=${filter.category}`;
    }

    // console.log(`[getKiosCategory] Memulai permintaan dengan filter: ${filterProductCategory}`);

    try {
      const response = await axios.get(
        `${API_URL}/api/v1/kios-product${filterProductCategory}`
      );
      // console.log("[getKiosCategory] Data produk diterima:", response.data);

      // Filter produk berdasarkan input pencarian
      const filtered = response.data.data.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredProducts(filtered);
      setAllCategories(response.data.data); // Simpan semua data untuk keperluan lain jika diperlukan
    } catch (err) {
      console.error("[getKiosCategory] Error mengambil produk:", err);
      setFilteredProducts([]);
      setAllCategories([]);
    }
  };

  React.useEffect(() => {
    console.log(
      `[useEffect] Memanggil getKiosCategory dengan search: "${debouncedSearch}" dan filter:`,
      filter
    );
    getKiosCategory(debouncedSearch, filter);
  }, [refresh, debouncedSearch, filter]);

  const categoryData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item, index) => ({
      id: item.id,
      no: index + 1,
      name: item.name,
      price: item.price,
      base_price: item.base_price,
      additional_cost: item.additional_cost,
      merchant_cost: item.Kios_Merchant_Cost ? item.Kios_Merchant_Cost[0].merchant_cost : item.merchant_cost,
      bassPrice: Number(item.base_price || 0) + Number(item.additional_cost || 0),
      products: item.Products ? item.Products.length : 0,
      hidden: item.hidden
    }));
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `${t("no")}`,
      selector: "no",
      sortable: true,
      width: "60px",
      style: {
        fontSize: "14px"
      }
    },
    {
      name: `${t("productName")}`,
      selector: "name",
      sortable: true,
      width: "250px",
      style: {
        fontSize: "14px"
      }
    },
    {
      name: `${t("basePrice")}`,
      selector: "base_price",
      sortable: true,
      width: "120px",
      right: true,
      format: row => row.base_price?.toLocaleString() || "0"
    },
    {
      name: `${t("additionalCost")}`,
      selector: "additional_cost",
      sortable: true,
      width: "130px",
      right: true,
      format: row => row.additional_cost?.toLocaleString() || "0"
    },
    {
      name: `${t("merchantCost")}`,
      selector: "merchant_cost",
      sortable: true,
      width: "130px",
      right: true,
      format: row => row.merchant_cost?.toLocaleString() || "0"
    },
    {
      name: `${t("price")}`,
      selector: "price",
      sortable: true,
      width: "120px",
      right: true,
      format: row => row.price?.toLocaleString() || "0"
    },
    {
      name: `${t("actions")}`,
      cell: (rows) => {
        return (
          <div style={{ padding: "8px 0" }}>
            <Dropdown>
              <Dropdown.Toggle variant="secondary" size="sm">
                <MoreHoriz color="action" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => showEditPpobProduct(rows)}
                >
                  {t("edit")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
      width: "100px",
      center: true
    }
  ];

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    console.log(`[handleSearch] Nilai pencarian diubah: "${searchValue}"`);
    setSearch(searchValue);
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter, [name]: value };
    console.log(`[handleFilter] Filter diubah: ${name} = "${value}"`);
    setFilter(filterData);
  };

  // Tambahkan state untuk saldo dengan nilai default yang aman
  const [saldoInfo, setSaldoInfo] = useState({ saldo: 0 });
  const authToken = useSelector((state) => state.auth.authToken);
  const BASE_URL = process.env.REACT_APP_API_URL || "https://api.beetpos.com";

  // Perbaiki fungsi fetchSaldoInfo
  const fetchSaldoInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/kios-transaction/direct-get-saldo`,
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      
      if (response.data?.statusCode === 201 && response.data?.data) {
        setSaldoInfo(response.data.data);
      } else {
        // Set default saldo jika tidak ada data
        setSaldoInfo({ saldo: 0 });
      }
    } catch (error) {
      console.error("Error fetching saldo:", error);
      // Set default saldo jika terjadi error
      setSaldoInfo({ saldo: 0 });
    }
  }, [authToken, BASE_URL]);

  // Panggil fetchSaldoInfo saat komponen dimount dan saat refresh
  useEffect(() => {
    fetchSaldoInfo();
  }, [fetchSaldoInfo]);

  // Tambahkan fungsi untuk export ke Excel
  const handleExportToExcel = () => {
    try {
      // Siapkan data untuk export
      const exportData = filteredProducts.map((item) => ({
        'Nama Produk': item.name,
        'Base Price': item.base_price,
        'Additional Cost': item.additional_cost || 0,
        'Merchant Cost': item.merchant_cost || 0,
        'Harga Total': item.price
      }));

      // Buat workbook baru
      const worksheet = XLSXUtils.json_to_sheet(exportData);
      const workbook = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(workbook, worksheet, 'Products');

      // Generate file Excel
      const excelBuffer = XLSXWrite(workbook, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Download file
      const fileName = `PPOB_Products_${new Date().toLocaleDateString()}.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Untuk IE
        window.navigator.msSaveOrOpenBlob(data, fileName);
      } else {
        // Untuk browser modern
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }

      toast.success(t('exportSuccess'));
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error(t('exportFailed'));
    }
  };

  return (
    <Row>
      {/* <ConfirmModal
        title={t("deleteProductCategory")}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        handleClick={handleDelete}
        state={showConfirm}
        closeModal={closeConfirmModal}
        loading={loading}
      /> */}

      {/* <ProductCategoryModal
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showAddCategory}
        closeModal={closeAddCategoryModal}
        loading={loading}
        alert={alertModal}
        title={t("addProductCategory")}
        formikCategory={formikAddCategory}
        inputRef={inputRef}
      /> */}

      <EditPpobProductModal
        t={t}
        hiddenCategory={hiddenCategory}
        handleHiddenCategory={handleHiddenCategory}
        state={showEditPpobModal}
        closeModal={closeEditPpobModal}
        loading={loading}
        alert={alertModal}
        title={t("editProduct")}
        formikPpobProduct={formikEditPpobProduct}
        inputRef={inputRef}
        handleRefresh={handleLocalRefresh}
      />

      <ConfirmModal
        title={`${t("delete")} ${selectedData.length} ${t(
          "selectedModifiers"
        )}`}
        body={t("areYouSureWantToDelete?")}
        buttonColor="danger"
        // handleClick={() => handleBulkDelete(selectedData)}
        state={showConfirmBulk}
        // closeModal={closeConfirmBulkModal}
        loading={loading}
      />

      <Col md={12}>
        {alert ? <Alert variant="danger">{alert}</Alert> : ""}
        <Paper 
          elevation={2} 
          style={{ 
            padding: "1.5rem",
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px"
          }}
        >
          <div className="headerPage" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="headerStart" style={{ flex: 1 }}>
              {!selectedData.length ? (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h3>{t("productTitle")}</h3>
                  </div>
                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Button
                      variant="success"
                      onClick={handleExportToExcel}
                      style={{ 
                        height: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                    >
                      <i className="fas fa-file-excel" />
                      {t("exportToExcel")}
                    </Button>
                    <div className="bg-light rounded p-3">
                      <h3 style={{ color: "grey", fontSize: "12px", fontWeight: "bold" }}>
                        Saldo saat ini
                      </h3>
                      <h3 style={{ color: "green", fontSize: "14px" }}>
                        Rp {saldoInfo?.saldo?.toLocaleString() || "0"}
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                <h3>
                  {selectedData.length}
                  {t("itemSelected")}
                </h3>
              )}
            </div>
          </div>

          <div className="filterSection lineBottom">
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text style={{ background: "transparent" }}>
                      <Search />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder={t("search")}
                    value={search}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </Col>

              <Col>
                <Row>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ alignSelf: "center", marginBottom: "0" }}
                      >
                        {t("product")}
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          name="category"
                          value={filter.category}
                          onChange={handleFilter}
                        >
                          <option value="">{t("all")}</option>
                          {allCategories.map((item) => {
                            return (
                              <option key={item.id} value={item.id} disabled={true}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredProducts)}
            // style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            // onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
        </Paper>
      </Col>
    </Row>
  );
};

export default ProductPPOB;
