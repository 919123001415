import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Select, { components } from "react-select";
import makeAnimated from 'react-select/animated';

// TODO: Replace these with actual Metronic component imports
const Table = ({ children }) => (
  <table className="table table-rounded table-striped border gy-7 gs-7">
    {children}
  </table>
);
const TableHead = ({ children }) => <thead>{children}</thead>;
const TableBody = ({ children }) => <tbody>{children}</tbody>;
const TableRow = ({ children }) => <tr>{children}</tr>;
const TableCell = ({ children }) => <td>{children}</td>;
const TableHeaderCell = ({ children }) => <th>{children}</th>;
const Button = ({ children, onClick, className }) => (
  <button onClick={onClick} className={`btn ${className}`}>
    {children}
  </button>
);

const BASE_URL = "https://api.beetpos.com"; // Tambahkan ini di bagian atas file

const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const getTodayDate = () => {
  const today = new Date();
  return getFormattedDate(today);
};

const getTwentyDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 20);
  return getFormattedDate(date);
};

const TopUpPPOB = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [dateStart, setDateStart] = useState(getTwentyDaysAgo());
  const [dateEnd, setDateEnd] = useState(getTodayDate());
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([{ value: 'all', label: 'All Business' }]);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [editStatus, setEditStatus] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [approvalModal, setApprovalModal] = useState(null);
  const [approvalDescription, setApprovalDescription] = useState("");
  const [saldoInfo, setSaldoInfo] = useState(null);

  const authToken = useSelector((state) => state.auth.authToken);

  const fetchBusinesses = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business`, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      const businessOptions = response.data.data.map(business => ({
        value: business.id,
        label: business.name
      }));
      setBusinesses([{ value: 'all', label: 'All Business' }, ...businessOptions]);
    } catch (error) {
      console.error("Error fetching businesses:", error);
    }
  }, [authToken]);

  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const endDateWithTime = new Date(dateEnd);
      endDateWithTime.setHours(23, 59, 59, 999);
      
      const response = await axios.get(
        `${BASE_URL}/api/v1/kios-topup`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            business_id: selectedBusinesses.some(business => business.value === 'all') ? null : selectedBusinesses.map(business => business.value).join(','),
            page: currentPage,
            per_page: 10,
            date_start: `${dateStart} 00:00:00`,
            date_end: `${dateEnd} 23:59:59`
          }
        }
      );

      if (response.data.statusCode === 200) {
        const transactions = response.data.data.map(transaction => ({
          id: transaction.id,
          code: transaction.code || '-',
          image: transaction.image || null,
          amount: transaction.amount,
          rawAmount: transaction.amount,
          description: transaction.top_up_description || '-',
          businessName: transaction.Business?.name || '-',
          status: transaction.status || '-',
          createdAt: transaction.createdAt
        }));

        setTransactions(transactions);
      }
    } catch (err) {
      console.error("Error fetching transactions:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [authToken, selectedBusinesses, currentPage, dateStart, dateEnd]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, selectedBusinesses, dateStart, dateEnd]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const today = getTodayDate();
    const twentyDaysAgo = getTwentyDaysAgo();
    
    if (name === "dateStart") {
      if (value < twentyDaysAgo) {
        setDateStart(twentyDaysAgo);
      } else if (value > today) {
        setDateStart(today);
      } else {
        setDateStart(value);
      }
    } else if (name === "dateEnd") {
      if (value > today) {
        setDateEnd(today);
      } else if (value < dateStart) {
        setDateEnd(dateStart);
      } else {
        setDateEnd(value);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleBusinessChange = (selectedOptions) => {
    let newSelection = selectedOptions;
    
    if (!selectedOptions) {
      newSelection = [{ value: 'all', label: 'All Business' }];
    } else if (selectedOptions.length > 1) {
      // Remove 'All' option if it exists and other options are selected
      newSelection = selectedOptions.filter(option => option.value !== 'all');
    } else if (selectedOptions.length === 0) {
      // If no selection, default to 'All'
      newSelection = [{ value: 'all', label: 'All Business' }];
    }
    
    setSelectedBusinesses(newSelection);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApprove = async (id, transaction) => {
    setApprovalModal({ id, action: "approve", transaction });
    setApprovalDescription("");
  };
  
  const handleReject = async (id, transaction) => {
    setApprovalModal({ id, action: "reject", transaction });
    setApprovalDescription("");
  };

  const handleApprovalSubmit = async () => {
    if (!approvalDescription.trim()) {
      alert("Mohon isi deskripsi terlebih dahulu.");
      return;
    }
  
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/kios-topup/update-admin/${approvalModal.id}`,
        {
          status: approvalModal.action === "approve" ? "approved" : "cancel",
          admin_description: approvalDescription,
          amount: approvalModal.transaction.rawAmount
        },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      if (response.data.statusCode === 200) {
        fetchTransactions();
        setApprovalModal(null);
      }
    } catch (error) {
      console.error("Error updating transaction:", error);
    }
  };

  const handleEdit = (transaction) => {
    setEditingTransaction(transaction);
    setEditStatus(transaction.status);
    setEditDescription(transaction.admin_description);
  };

  const handleSaveEdit = async () => {
    try {
      const originalAmount = editingTransaction.amount;
      console.log("Original amount for edit:", originalAmount);
      
      const response = await axios.put(
        `${BASE_URL}/api/v1/kios-topup/update-admin/${editingTransaction.id}`,
        {
          status: editStatus,
          admin_description: editDescription,
          amount: originalAmount
        },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      if (response.data.statusCode === 200) {
        fetchTransactions();
        setEditingTransaction(null);
      }
    } catch (error) {
      console.error("Error updating transaction:", error);
    }
  };

  const renderImage = (imagePath) => {
    if (!imagePath) return <span>Tidak ada Gambar</span>;
    
    const fullImageUrl = `${BASE_URL}${imagePath}`;
    return (
      <img 
        src={fullImageUrl} 
        alt="Bukti Transfer" 
        style={{width: "50px", height: "50px", cursor: "pointer"}}
        onClick={() => setSelectedImage(fullImageUrl)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/path/to/placeholder-image.jpg"; 
        }}
      />
    );
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null;

    return (
      <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{position: 'relative'}}>
              <h5 className="modal-title">Gambar Bukti Transfer</h5>
              <button 
                type="button" 
                onClick={onClose}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  background: 'none',
                  border: 'none',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#000',
                  padding: '0',
                  lineHeight: '1'
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img src={imageUrl} alt="Bukti Transfer" style={{width: '100%'}} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fetchSaldoInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/kios-transaction/direct-get-saldo`,
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      if (response.data.statusCode === 201) {
        setSaldoInfo(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching saldo:", error);
    }
  }, [authToken]);

  useEffect(() => {
    fetchSaldoInfo();
  }, [fetchSaldoInfo]);

  const filteredTransactions = transactions.filter(transaction => {
    const matchesSearch = transaction.businessName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         transaction.description?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === "ALL" || transaction.status?.toLowerCase() === statusFilter.toLowerCase();
    
    const matchesBusiness = selectedBusinesses.some(business => business.value === 'all') ||
                           selectedBusinesses.some(business => 
                             business.value === transaction.businessId || 
                             business.label === transaction.businessName
                           );
    
    const transactionDate = new Date(transaction.createdAt);
    const startDateTime = new Date(`${dateStart} 00:00:00`);
    const endDateTime = new Date(`${dateEnd} 23:59:59`);
    
    const matchesDate = transactionDate >= startDateTime && transactionDate <= endDateTime;
    
    return matchesSearch && matchesStatus && matchesBusiness && matchesDate;
  });

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title">PPOB Top Up Dashboard</h3>
        {saldoInfo && (
          <div className="d-flex align-items-center">
            <div className="bg-light rounded p-3 mr-3">
              <div className="text-muted small mb-1">Saldo Saat Ini</div>
              <div className="font-weight-bold text-success">
                Rp {saldoInfo.saldo.toLocaleString()}
              </div>
            </div>
            <div className="bg-light rounded p-3">
              <div className="text-muted small mb-1">Maksimal Saldo</div>
              <div className="font-weight-bold">
                Rp {saldoInfo.max_saldo.toLocaleString()}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="form-group row mb-6">
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Select
              isMulti
              value={selectedBusinesses}
              onChange={handleBusinessChange}
              options={businesses}
              placeholder="Pilih Bisnis"
              closeMenuOnSelect={false}
              components={{
                Option: ({ children, ...props }) => {
                  return (
                    <components.Option {...props}>
                      <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                        style={{ marginRight: '8px' }}
                      />
                      {children}
                    </components.Option>
                  );
                },
                MultiValue: ({ children, ...props }) => (
                  <components.MultiValue {...props}>
                    {children}
                    <span
                      onClick={() => {
                        const newValue = selectedBusinesses.filter(
                          option => option.value !== props.data.value
                        );
                        handleBusinessChange(newValue.length ? newValue : [{ value: 'all', label: 'All Business' }]);
                      }}
                      style={{
                        cursor: 'pointer',
                        marginLeft: '6px',
                        color: '#999'
                      }}
                    >
                      ×
                    </span>
                  </components.MultiValue>
                )
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? '#e6f3ff' : 'white',
                  color: '#333',
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  },
                  display: 'flex',
                  alignItems: 'center'
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: '#e6f3ff',
                  borderRadius: '4px',
                  padding: '2px 6px'
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  color: '#333'
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: '38px'
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: '#999'
                })
              }}
            />
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <input
              type="text"
              className="form-control"
              placeholder="Cari transaksi..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <label>Tanggal Mulai</label>
            <input
              type="date"
              className="form-control"
              name="dateStart"
              value={dateStart}
              onChange={handleDateChange}
              min={getTwentyDaysAgo()}
              max={dateEnd}
            />
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <label>Tanggal Akhir</label>
            <input
              type="date"
              className="form-control"
              name="dateEnd"
              value={dateEnd}
              onChange={handleDateChange}
              min={dateStart}
              max={getTodayDate()}
            />
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6 mt-2">
            <select
              className="form-control"
              value={statusFilter}
              onChange={handleStatusChange}
            >
              <option value="ALL">All Status</option>
              <option value="new">New</option>
              <option value="approved">Approved</option>
              <option value="cancel">Cancel</option>
            </select>
          </div>
        </div>

        {loading ? (
          <p>Memuat data...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : filteredTransactions.length === 0 ? (
          <p>Tidak ada data transaksi</p>
        ) : (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Kode</th>
                  <th>Gambar</th>
                  <th>Amount</th>
                  {/* <th>Balance</th> */}
                  <th>Description</th>
                  <th>Business</th>
                  {/* <th>Type</th> */}
                  {/* <th>Additional Cost</th>
                  <th>Merchant Cost</th> */}
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.code}</td>
                    <td>{renderImage(transaction.image)}</td>
                    <td>Rp {transaction.amount?.toLocaleString()}</td>
                    <td>{transaction.description}</td>
                    <td>{transaction.businessName}</td>
                    <td>
                      <span className={`fw-medium ${
                        transaction.status === "new" ? "text-primary" : 
                        transaction.status === "approved" ? "text-success" : 
                        transaction.status === "cancel" ? "text-danger" : 
                        ""
                      }`}>
                        {transaction.status}
                      </span>
                    </td>
                    <td>
                      {transaction.status === "new" && (
                        <>
                          <button 
                            className="btn btn-sm btn-success mr-2" 
                            onClick={() => handleApprove(transaction.id, transaction)}
                          >
                            Approve
                          </button>
                          <button 
                            className="btn btn-sm btn-danger" 
                            onClick={() => handleReject(transaction.id, transaction)}
                          >
                            Reject
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Image Modal */}
            <ImageModal 
              imageUrl={selectedImage} 
              onClose={() => setSelectedImage(null)} 
            />

            {/* Modal Edit */}
            {editingTransaction && (
              <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Transaksi</h5>
                      <button type="button" className="close" onClick={() => setEditingTransaction(null)}>
                        <span>&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Status</label>
                        <select 
                          className="form-control" 
                          value={editStatus} 
                          onChange={(e) => setEditStatus(e.target.value)}
                        >
                          <option value="approved">Approved</option>
                          <option value="cancel">Cancel</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Deskripsi Admin</label>
                        <textarea 
                          className="form-control" 
                          value={editDescription} 
                          onChange={(e) => setEditDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setEditingTransaction(null)}>Batal</button>
                      <button type="button" className="btn btn-primary" onClick={handleSaveEdit}>Simpan</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Approval/Rejection Modal */}
            {approvalModal && (
              <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {approvalModal.action === "approve" ? "Approve" : "Reject"} Transaksi
                      </h5>
                      <button type="button" className="close" onClick={() => setApprovalModal(null)}>
                        <span>&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>Deskripsi Admin</label>
                        <textarea 
                          className="form-control" 
                          value={approvalDescription} 
                          onChange={(e) => setApprovalDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setApprovalModal(null)}>Batal</button>
                      <button type="button" className="btn btn-primary" onClick={handleApprovalSubmit}>Simpan</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-secondary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                className="btn btn-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopUpPPOB;
